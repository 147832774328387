var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table-with-header',{attrs:{"denied":_vm.denied,"data":{
  headerOptions : { withAddNew : false , withSearch : this.pending ? true : false , withExport : true},
  tableHeader : _vm.pendingCoursesHeaders ,
  tableItems : _vm.pendingCoursesRows ,
  extraOptions : {
    footerOptions : {
      itemsPerPageText : this.$t('table.rowNumber')
    },
  },
  withActions : true
}},on:{"search":_vm.search},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.primaryColor,"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.settingsIcon))])],1)]}}],null,true)},[_c('v-list',[(!_vm.denied)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name : 'editCourse' , params : {id : data.course_id}, query : { type : 'module' } }}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("adminCourses.module")))])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"color":_vm.darkColor},on:{"click":function($event){return _vm.$emit('details', { id: data.course_id })}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('courseStatus.details'))+" ")])],1),(_vm.pending)?_c('v-list-item',{attrs:{"color":_vm.primaryColor},on:{"click":function($event){return _vm.$emit('confirm', { id: data.course_id })}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('courseStatus.accept'))+" ")])],1):_vm._e(),(_vm.pending)?_c('v-list-item',{attrs:{"color":_vm.primaryColor},on:{"click":function($event){return _vm.$emit('resubmit', { id: data.course_id })}}},[_c('v-list-item-title',[_vm._v(" إعادة الطلب ")])],1):_vm._e(),(_vm.pending)?_c('v-list-item',{attrs:{"color":_vm.RColor},on:{"click":function($event){return _vm.$emit('reject', { id: data.course_id })}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('courseStatus.reject'))+" ")])],1):_vm._e()],1)],1)],1)]}}])},[_c('h1',{staticClass:"ma-0 pa-2",style:({'color' : this.primaryColor}),attrs:{"slot":"tableTitle"},slot:"tableTitle"},[_vm._v(_vm._s(this.pending ? _vm.$t('dashboardMain.pendingCourses.pendingCourses') : this.denied ? _vm.$t('dashboardMain.pendingCourses.deniedCourses') : _vm.$t('dashboardMain.pendingCourses.liveCourses'))+" "),(_vm.pending && _vm.isAdmin)?_c('v-subheader',[_vm._v(_vm._s(_vm.getPendingCoursesCount)+" "+_vm._s(_vm.$t('dashboardMain.pendingCourses.course')))]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }