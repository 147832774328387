<template>
  <v-dialog persistent max-width="600px" v-model="isOpen">
    <v-card max-height="100%" class="confirm-delete">
      <v-card-title class="headline mb-9"> {{ $t('dashboardMain.pendingCourses.confirmStatus') }} </v-card-title>
      <v-form ref="form" class="px-4">
        <v-textarea v-if="statusMethod !=='confirm'" v-model="message" height="50" label="الرسالة التوضيحية" :rules="[(v) => !!v || this.$t('required.field')]"></v-textarea>
      </v-form>
      <v-card-actions>
        <v-row class="d-flex flex-column flex-md-row justify-space-around align-center my-3">
          <base-button
            class="text-large my-2"
            :options="{ block: false, color: RColor, isLoading: statusLoader}"
            @click="confirmMethod()"
          >
            {{ $t('dashboardMain.pendingCourses.yes') }}
          </base-button>
          <base-button
            class="text-large my-2"
            :options="{ block: false }"
            @click="close()"
          >
            {{ $t('dashboardMain.pendingCourses.no') }}
          </base-button>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from "../../../../../core/Base/Buttons/BaseButton";

export default {
  name: "ConfirmDelete",
  components: { BaseButton },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    id: Number,
    statusMethod: String,
  },

  data () {
    return {
      statusLoader: false,
      message: '',
    }
  },

  methods: {
    confirmMethod() {
      if (this.confirmMethod !== 'confirm') {
        if (!this.$refs.form.validate()) return;
      }
      this.statusLoader = true
      this.$emit("handelRequest", { id: this.id, method: this.statusMethod, message: this.message });
    },
    close() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style scoped>
.text-large {
  font-size: 18px !important;
}
button {
  width: 200px !important;
}

.confirm-delete {
  overflow: hidden !important;
}
</style>
