<template>
  <base-table-with-header @search="search" :denied="denied" :data="{
    headerOptions : { withAddNew : false , withSearch : this.pending ? true : false , withExport : true},
    tableHeader : pendingCoursesHeaders ,
    tableItems : pendingCoursesRows ,
    extraOptions : {
      footerOptions : {
        itemsPerPageText : this.$t('table.rowNumber')
      },
    },
    withActions : true
  }"
  >

    <h1 slot="tableTitle" class="ma-0 pa-2" :style="{'color' : this.primaryColor}">{{ this.pending ? $t('dashboardMain.pendingCourses.pendingCourses') : this.denied ? $t('dashboardMain.pendingCourses.deniedCourses') : $t('dashboardMain.pendingCourses.liveCourses') }}
      <v-subheader v-if="pending && isAdmin">{{ getPendingCoursesCount }} {{ $t('dashboardMain.pendingCourses.course') }}</v-subheader>
    </h1>
    <template #default="{data}">

      <div class="text-center">
        <v-menu offset-y open-on-hover min-width="150">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="primaryColor"
              icon
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon large>{{ settingsIcon }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <router-link v-if="!denied" :to="{ name : 'editCourse' , params : {id : data.course_id}, query : { type : 'module' } }" style="text-decoration: none">
              <v-list-item>
                <v-list-item-title>{{
                  $t("adminCourses.module")
                }}</v-list-item-title>
              </v-list-item>
            </router-link>

              <v-list-item @click="$emit('details', { id: data.course_id })" :color="darkColor">
                <v-list-item-title>
                  {{ $t('courseStatus.details') }}
                </v-list-item-title>
              </v-list-item>

            <v-list-item v-if="pending" @click="$emit('confirm', { id: data.course_id })" :color="primaryColor">
              <v-list-item-title>
                {{ $t('courseStatus.accept') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="pending" @click="$emit('resubmit', { id: data.course_id })" :color="primaryColor">
              <v-list-item-title>
                إعادة الطلب
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="pending" @click="$emit('reject', { id: data.course_id })" :color="RColor">
              <v-list-item-title>
                {{ $t('courseStatus.reject') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
           
    </template>
  </base-table-with-header>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseTableWithHeader from "./PendingTable";
import moment from 'moment';

export default {
  name: 'PendingCourses',

  props: [
    'pending', // to show either the live or the pending courses
    'refresh',
    'courses',
    'denied'
  ],  

  data () {
    return {
      pendingCoursesHeaders: [
        { text: this.$t('dashboardMain.pendingCourses.courseTitle'), align: 'center', sortable: true, value: 'course_title' },
        {text: this.$t('dashboardMain.pendingCourses.categoryTitle'), value: 'category_title', align: 'center'},
        {text: this.$t('dashboardMain.pendingCourses.courseType'), value: 'course_type', align: 'center'},
        {text: this.$t('dashboardMain.pendingCourses.instructors'), value: 'instructor_name', align: 'center'},
        {text: this.$t('dashboardMain.pendingCourses.submitDate'), value: 'submit_date', align: 'center'},
        {text: '', value: 'actions', align: 'center'},
      ],

      pendingCoursesRows: []
    }
  },

  components: {
    BaseTableWithHeader
  },

  created () {
    this.init();
  },

  computed: {
    ...mapGetters("DashboardMain", ["getPendingCoursesCount", "getPendingCourses", "getLiveCourses"]),
    isAdmin () {
      return (localStorage.getItem('adminType') === 'super' || localStorage.getItem('adminType') === 'normal')
    },
  },
  watch: {
    courses: function() {
      this.displayPendingCourses();
      this.init();
    }
  },

  methods: {
    ...mapActions("DashboardMain", ["fetchPendingCoursesWithSearch", "fetchPendingCoursesCount", "fetchDeniedCourses"]),

    init () {
        if (this.pending) {
        this.fetchPendingCoursesCount();
      } 
      this.displayPendingCourses();
    },

    /**
     * set up the pending courses dates before displaying in the table
     */
    displayPendingCourses () {
      this.pendingCoursesRows = [];
       this.courses.forEach(course => {
        const displayCourse = {}
        displayCourse.submit_date = moment(course.createdAt).format("YYYY-MM-DD");
        displayCourse.course_id = course.id;
        displayCourse.course_title = course.title;
        displayCourse.category_title = course.category.title;
        displayCourse.course_type = course.type;
        displayCourse.instructor_name = '';
        course.courseInstructors.forEach(instructor => {
          displayCourse.instructor_name += course.courseInstructors[0].instructor.firstName + ' ' + course.courseInstructors[0].instructor.lastName + ', '
        })
        this.pendingCoursesRows.push(displayCourse)
      });
      console.log(this.pendingCoursesRows)
    },

    /**
     * search emitted from the table's search bar
     */
    search(payload) {
      console.log(payload)
      this.fetchPendingCoursesWithSearch(payload).then(() => {
        this.displayPendingCourses()
      })
    },
  }
}
</script>