<template>
  <v-container>
    <v-row v-if="isAdmin">
        <Counters :counters="getCounters"/>
    </v-row>

    <v-divider v-if="isAdmin"></v-divider>

    <v-row v-if="isAdmin" class="d-flex flex-column flex-md-row"  justify="space-around">
      <v-col>
        <h1 class="text-center" :style="{'color' : this.primaryColor}">{{ $t('dashboardMain.chartsArea.topCourses') }}</h1>

        <v-row justify="center" align="center" class="text-center">
          <v-container class="customWidth">
            <div>{{ $t('dashboardMain.chartsArea.topCount') }}</div>
            <v-text-field filled @input="updateTopCoursesChart" class="pa-3" type="number" v-model="topCoursesNumber"></v-text-field>
          </v-container>
        </v-row>

        <TopCoursesChart
          v-if="topCoursesChartdataLoaded"
          :chartdata="topCoursesChartdata"
        />
      </v-col>

      <v-col>
        <h1 class="text-center" :style="{'color' : this.primaryColor}">{{ $t('dashboardMain.chartsArea.enrollmentPerformance') }}</h1>

        <v-row justify="center" align="center" class="text-center">
          <v-container class="d-flex flex-column justify-center align-center">
            <div class="mt-6">{{ $t('dashboardMain.chartsArea.period') }}</div>

            <v-radio-group row v-model="period" @change="updatePerformanceChart" class="mb-6">
              <v-radio
                v-for="(n, index) in selectPeriod"
                :key="index"
                :label="n.label"
                :value="n.value"
              ></v-radio>
            </v-radio-group>
          </v-container>
        </v-row>
          
        <PerformanceChart 
          v-if="performanceChartdataLoaded"
          :chartdata="performanceChartdata"
        /> 
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <PendingCourses :courses="pendingCourses" :pending="true" @confirm="confirm" @reject="reject" @resubmit="resubmit" @details="showCourseDetails"/>

    <v-divider></v-divider>

    <PendingCourses :courses="liveCourses" :pending="false" @details="showCourseDetails"/>

    <v-divider></v-divider>

    <!-- <PendingCourses :courses="deniedCourses" :pending="false" :denied="true" @details="showDeniedCourseDetails"/> -->

    <DetailsModal v-if="showDetails" :isDenied="isDenied" :details="showDetails" :id="detailsId" @close="closeConfirm"/>

    <confirm-status
      v-if="statusConfirm >= 0"
      v-bind="{ id: statusConfirm, statusMethod: statusMethod, isOpen: true }"
      @handleClose="closeConfirm"
      @handelRequest="changeStatus"
    ></confirm-status>
  </v-container>
</template>

<script>
import PendingCourses from "./Components/PendingCourses";
import TopCoursesChart from './Components/TopCoursesChart';
import PerformanceChart from './Components/PerformanceChart';
import Counters from './Components/Counters';
import ConfirmStatus from './Components/confirmStatus';
import DetailsModal from './Components/DetailsModal'
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: "Main",

  components: { Counters, TopCoursesChart, PerformanceChart, ConfirmStatus, PendingCourses, DetailsModal },

  data() {
    return {
      statusConfirm: -1,
      statusMethod: '',
      pendingCourses: [],
      liveCourses: [],
      deniedCourses: [],
      detailsId: -1,
      showDetails: false,
      isDenied: false,

      topCoursesChartdataLoaded: false, // for top courses chart
      topCoursesChartdata: null, // for top courses chart
      topCoursesNumber: 3, // for top courses chart

      performanceChartdataLoaded: false, //for enrollment performance chart
      performanceChartData: null, //for enrollment performance chart
      period: 'week',  //for enrollment performance chart
      // for the radio select for enrollment performance chart
      selectPeriod: [ 
        {
          label: this.$t('dashboardMain.chartsArea.week'),
          value: 'week'
        },
        {
          label: this.$t('dashboardMain.chartsArea.month'),
          value: 'month'
        },
        {
          label: this.$t('dashboardMain.chartsArea.year'),
          value: 'year'
        }
      ]
    }
  },

  created () {
    if(this.isAdmin) {
      this.fetchCounters();
      this.updateTopCoursesChart();
      this.updatePerformanceChart();
    }
    this.init();
  },

  methods: {
    ...mapActions("DashboardMain", ["fetchCounters", "fetchTopCourses", "fetchEnrollmentPeroformance", "updateStatus", "fetchLiveCourses", "fetchPendingCourses", "fetchDeniedCourses"]),

    init () {
      this.fetchPendingCourses().then(() => {
        this.pendingCourses = this.getPendingCourses;
      });
      this.fetchLiveCourses().then(() => {
        this.liveCourses = this.getLiveCourses;
      });
      // this.fetchDeniedCourses().then(() => {
      //   this.deniedCourses = this.getDeniedCourses;
      // });
    },

    showCourseDetails (payload) {
      this.detailsId = payload.id;
      this.showDetails = true;
    },
    showDeniedCourseDetails (payload) {
      this.isDenied = true;
      this.detailsId = payload.id;
      this.showDetails = true;
    },

    confirm (payload) {
      this.statusMethod = 'confirm';
      this.statusConfirm = payload.id
    },

    reject (payload) {
      this.statusMethod = 'reject';
      this.statusConfirm = payload.id;
    },

    resubmit (payload) {
      this.statusMethod = 'resubmit';
      this.statusConfirm = payload.id;
    },

    closeConfirm () {
      this.showDetails = false;
      this.statusConfirm = -1;
      this.isDenied = false;
    },

    changeStatus (payload) {
      console.log(payload.id)
      let status = '';
      if (payload.method === 'confirm') {
        status = 'live';
        payload.message = undefined;
      } else if (payload.method === 'reject') {
        status = 'denied'
      } else if (payload.method === 'resubmit') {
        status = 'resubmit'
      }
      console.log(status)
      this.updateStatus({course_id: payload.id, status: status, description: payload.message}).then(() => {
        this.statusConfirm = -1;
        this.init();
        this.refreshPending = true;
      })
    },

    /**
     * to fetch the top courses data and set up the chart accordingly
     */
    updateTopCoursesChart () {
      this.topCoursesChartdataLoaded = false;
      this.fetchTopCourses(this.topCoursesNumber).then(() => {
        this.setTopCoursesChart();
      });
    },

    /**
     * Set up the top courses chart 
     */
    setTopCoursesChart () {
      let tempData = []
      let tempLabels = []
      let tempColors = []
      this.getTopCourses.forEach(course => {
        tempData.push(course.enrolls)
        tempLabels.push(course.course_title)
        tempColors.push(this.getRandomColor())
      })
      if (this.getTopCourses.length === 3) {
        tempColors = [this.primaryColor, this.RColor, this.YColor]
      }
      this.topCoursesChartdata = {
        datasets: [{
          data: tempData,
          backgroundColor: tempColors
        }],
        labels: tempLabels
      }
      this.topCoursesChartdataLoaded = true;
    },

    /**
     * to fetch the enrollment performance data and set up the chart accordingly
     */
    updatePerformanceChart () {
      this.performanceChartdataLoaded = false;
      this.fetchEnrollmentPeroformance(this.period).then((res) => {
        console.log(res)
        this.setPerformanceChart();
      })
    },

    /**
     * Set up the enrollment performance chart using chartjs Line chart
     */
    setPerformanceChart () {
      let tempData = []
      let tempLabels = []
      let tempColors = []
      this.getEnrollmentPeroformance.forEach(performance => {
        tempData.push(performance.enrolls);
        tempColors.push(this.getRandomColor);
        switch(this.period) {
          case 'year':
            tempLabels.push(performance.enroll_month);
            break;
          case 'month':
            tempLabels.push(performance.enroll_week);
            break;
          case 'week':
            tempLabels.push(this.localizeLabel(performance.enroll_day));
        }
      });
      this.performanceChartdata = {
        datasets: [{
          data: tempData,
          backgroundColor: this.primaryColor,
          label: this.$t('dashboardMain.chartsArea.enrollmentPerformance')
        }],
         labels: tempLabels
      };
      this.performanceChartdataLoaded = true;
    },

    /**
     * returns a random color to use in the top courses chart to represent an arc
     */
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    /**
     * to localize the week days fetched from the backend in the performance chart
     */
    localizeLabel (label) {
      switch (label) {
        case 'Friday': 
          return this.$t('weekDays.friday');
        case 'Saturday': 
          return this.$t('weekDays.saturday');
        case 'Sunday': 
          return this.$t('weekDays.sunday');
        case 'Monday': 
          return this.$t('weekDays.monday');
        case 'Tuesday': 
          return this.$t('weekDays.tuesday');
        case 'Wednesday': 
          return this.$t('weekDays.wednesday');
        case 'Thursday': 
          return this.$t('weekDays.thursday');
        default: 
          return label;
      }
    }
  },
  
  computed: {
    ...mapGetters("DashboardMain", ["getCounters", "getTopCourses", "getEnrollmentPeroformance", "getPendingCourses", "getLiveCourses", "getDeniedCourses"]),
 
     isAdmin() {
      return (localStorage.getItem('adminType') === 'super' || localStorage.getItem('adminType') === 'normal')
    }
  }
}
</script>

<style scoped>
.customWidth {
  width: 250px !important;
}
</style>
