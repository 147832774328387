<template>
  <v-card
    elevation="7"
    outlined
    class="base-card"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1 d-flex justify-lg-space-between align-center" >
          <slot name="title"></slot>
          <slot name="icon"></slot>
        </v-list-item-title>
        <v-list-item-subtitle>
          <slot name="subTitle"></slot>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "BaseAnalysisCard"
}
</script>

<style scoped>
    .base-card {
        border-radius: 20px;
    }
</style>
