<template>
  <v-container class="d-flex flex-column flex-md-row justify-space-around align-center">
    <base-analysis-card class="my-3">
      <div slot="title">{{ $t('dashboardMain.counters.instructors') }}</div>
      <div slot="icon">
        <v-icon class="mx-3">{{ accountSupervisorIcon }}</v-icon>
      </div>
      <div slot="subTitle" class="pa-2 text-center display-1">{{ counters.instructors }}</div>
    </base-analysis-card>
    
    <base-analysis-card class="my-3">
      <div slot="title">{{ $t('dashboardMain.counters.courses') }}</div>
      <div slot="icon">
        <v-icon class="mx-3">{{ bookIcon }}</v-icon>
      </div>
      <div slot="subTitle" class="pa-2 text-center display-1">{{ counters.courses }}</div>
    </base-analysis-card>
    
    <base-analysis-card class="my-3">
      <div slot="title">{{ $t('dashboardMain.counters.students') }}</div>
      <div slot="icon">
        <v-icon class="mx-3">{{ schoolIcon }}</v-icon>
      </div>
      <div slot="subTitle" class="pa-2 text-center display-1">{{ counters.students }}</div>
    </base-analysis-card>
  </v-container>
</template>
<script>
import BaseAnalysisCard from "../../../../../core/Base/Cards/BaseAnalysisCard";

export default {
  name: 'Counters',
  components: { BaseAnalysisCard },
  props: [
    'counters'
  ]
}
</script>