<template>
  <v-dialog max-width="900px" persistent v-model="details">
    <v-card width="100%">   
      <v-container>
        <v-hover v-slot="{hover}">
          <v-icon @click="$emit('close')" x-large :color="hover ? 'black' : ''">{{ closeIcon }}</v-icon>
        </v-hover>
          <v-row justify="center">
            <v-col cols="12" md="6">

              <v-card class="my-3">
                <v-card-text>{{ $t('adminCourses.courseTitle') }}</v-card-text>
                <v-card-title>{{ form.title }}</v-card-title>
              </v-card>

              <v-card class="my-3">
                <v-card-text>{{ $t('instructors.instructorName') }}</v-card-text>
                <v-card-title v-for="instructor in form.courseInstructors" :key="instructor.id">{{ instructor.instructor.firstName + ' ' + instructor.instructor.lastName }}</v-card-title>
              </v-card>

              <v-card class="my-3">
                <v-card-text>{{ $t('adminCourses.categoryTitle') }}</v-card-text>
                <v-card-title>{{ form.category.title }}</v-card-title>
              </v-card>

              <v-card class="my-3">
                <v-card-text>{{ $t('adminCourses.price') }}</v-card-text>
                <v-card-title>{{ form.price }}</v-card-title>
              </v-card>

              <v-card class="my-3">
                <v-card-text>{{ $t('learningPaths') }}</v-card-text>
                <v-card-title v-for="path in form.trainingPathCourses" :key="path.id">{{ path.trainingPath.title}}</v-card-title>
              </v-card>

              <v-img class="my-3" :src="getImg" max-width="350" max-height="400"></v-img>
            </v-col>

            <v-col cols="12" md="6">
              <v-row justify="center">
                <v-col cols="12" md="12">

                    <v-card class="my-3">
                      <v-card-text>{{ $t('adminCourses.type') }}</v-card-text>
                      <v-card-title>{{ form.type }}</v-card-title>
                    </v-card>

                    <v-card class="my-3">
                      <v-card-text>{{ $t('newAdminCourses.targets') }}</v-card-text>
                      <v-card-title>{{ form.targets }}</v-card-title>
                    </v-card>

                    <v-card class="my-3">
                      <v-card-text>{{ $t('newAdminCourses.requirements') }}</v-card-text>
                      <v-card-title>{{ form.requirements }}</v-card-title>
                    </v-card>

                    <v-card class="my-3">
                      <v-card-text>{{ $t('newAdminCourses.description') }}</v-card-text>
                      <v-card-title>{{ form.description }}</v-card-title>
                    </v-card>

                    <OfflineForm v-for="(course, index) in this.getOfflineData.offlineCourses" :key="course.id" :data="course" :courseId="form.course_id" :index="index" :isView="true" :languages="languages" @close="$emit('handleClose')" @prepare="prepareOffline" @cancel="cancelNewOffline"/>
                   

                </v-col>

              </v-row>

            </v-col>
          <v-btn @click="$emit('close')" :color="YColor" class="white--text px-12 mb-4 mx-2">
            {{ $t('form.cancel') }}
          </v-btn>
          <router-link v-if="!isDenied" :to="{ name : 'editCourse' , params : {id : this.id}, query : { type : 'module' } }" style="text-decoration: none">
        <v-btn
          class="px-12 mx-2"
          :color="primaryColorOverlay"
        >
          {{ $t('adminCourses.module') }}
        </v-btn>
      </router-link>
          </v-row>
      </v-container>
      
    </v-card>
  </v-dialog>
</template>
<script>
import OfflineForm from '../../Courses/Components/OfflineForm'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DetailsModal',
  components: {
    OfflineForm
  },
  props: [
    'details',
    'id',
    'isDenied'
  ],
  data () {
    return {
      form: {
        category: {
          title: ''
        },
        trainingPathCourses: [
          {
            trainingPath: {
              title: ''
            }
          }
        ]
      },
    }
  },
  created () {
    this.fetchOfflineData(this.id).then(() => {
      console.log(this.getOfflineData)
      if (!this.getOfflineData.trainingPathCourses[0].trainingPath) {
        this.getOfflineData.trainingPathCourses[0].trainingPath = { title : '' };
      }
      this.form = this.getOfflineData;
    })
  },
  methods: {
    ...mapActions("Courses", ["fetchOfflineData"])
  },
  computed: {
    ...mapGetters("Courses", ["getOfflineData"]),
    getImg() {
      return `https://bact-back.usol.ca${this.form.thumbnail}`;
    },
  }
}
</script>