<template>
  <v-row class="d-flex align-center uk-margin-auto" style="justify-content: flex-end !important">
    <download-excel 
      :data="data.tableItems"
      :name="data.headerOptions.withSearch ? 'Pending_Courses' : denied ? 'Denied_Courses' : 'Live_Courses'">
      <v-btn class="white--text ma-2" v-if="data.headerOptions.withExport" :color="this.YColor">
        <v-icon>{{this.downloadIcon}}</v-icon>
        {{ $t('table.export') }} 
      </v-btn>
    </download-excel>

    <form uk-grid lazy-validation v-on:submit.prevent @submit="$emit('search', {searchTerm: search})"  v-if="data.headerOptions.withSearch" class="uk-search uk-search-default">
      <div class="uk-width-auto uk-position-relative">
        <span class="uk-search-icon-flip" :style="{'color':this.primaryColor }" uk-search-icon></span>
        <input class="uk-search-input"  v-model="search" type="search" :placeholder="$t('navBar.search')">
      </div>
    </form>
  </v-row>
</template>

<script>
export default {
  name: "TableActionsNav",
  props : {
    options : Object,
    data: Object,
    denied: Boolean
  },
  data(){
    return {
      search : '',
    }
  }
}
</script>

<style scoped>

</style>
